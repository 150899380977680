import React from "react";

const About = () => {
    return (
        <div className="content">
            
            <section id="hero">
                <img src="images/tableau_ws.jpg"  />
                <h1>
                Jason Livingston
                </h1>
                <h3>
                About Me
                </h3>
            </section>

            <section >
                <p>
                I'm Jason Livingston, a Senior Manager of Data Science based in New York City. I have
                practiced as a Senior Data Scientist, a Manager of Marketing Analytics, and as a Business
                Analyst focusing in Mixed Media Modeling, predictive sales modeling, the sourcing and 
                scraping of valuable data and natural language processing, with the
                goal of measuring the success of advertising campaigns and optimizing.
                </p><br></br>
                <p>
                I have also worked as a consulting Data Scientist with experience in 
                the insurance industry, investment banking, website tagging and  
                performance analytics, serving in supporting roles and as a technical lead.
                </p><br></br>
                <p>
                I graduated Summa Cum Laude with a BS in Mathematics from Concordia University Texas, where I was inducted 
                into the National College Athlete Honor Society as a goalkeeper on the Men's Soccer team.
                I hold a Master of Science in Applied Analytics from Columbia University, where I graduated with the Distinguished 
                Student Leader honor, having served as the Director of Technology for the Applied Analytics Club.
                </p><br></br>
                <p>
                Today, I work in the video gaming industry. I also served as an Adjunct Associate 
                Faculty Member in Columbia University's Applied Analytics program from 2019 to 2022, and again from Fall 2024, 
                teaching courses in Machine Learning and Data Science Consulting. 
                </p>
            </section>
            
            <section >
                <h1>
                Career
                </h1>
                <h3>
                Take Two Interactive Software Inc. | July 2019 - Present
                </h3>
                <h5>
                Senior Manager, Data Science, June 2022 - Present
                </h5>
                <h5>
                Manager, Data Science, March 2021 - May 2022
                </h5>
                <h5>
                Senior Data Scientist, July 2019 - February 2021
                </h5>
                <h3>
                Columbia University | June 2019 - December 2022, September 2024 - Present
                </h3>
                <h5>
                Adjunct Associate Faculty
                </h5>
                <h3>
                Fulcrum Analytics | June 2018 - July 2019
                </h3>
                <h5>
                Senior Data Scientist
                </h5>
                <h3>
                Two Nil Media | April 2016 - June 2018
                </h3>
                <h5>
                Data Scientist, January 2018 - June 2018
                </h5>
                <h5>
                Business Insights Analyst, April 2016 - December 2017
                </h5>
            </section>

            <section >
                <h1>
                Education
                </h1>
                <h3>
                Columbia University in the City of New York | August 2018
                </h3>
                <h5>
                Master of Science - Applied Analytics
                </h5>
                <h3>
                Concordia University Texas | December 2015
                </h3>
                <h5>
                Bachelor of Science - Mathematics
                </h5>
            </section>
            
        </div>
    );
};

export default About;