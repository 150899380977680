import React from "react";
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div className="content">
            
            <section id="hero">
                <img src="images/new-headshot.jpg" class="header_img" />
                <h1>
                Jason Livingston
                </h1>
                <h3>
                Data Scientist in New York City
                </h3>
            </section>

            <section className="card" >
                <h3>Greetings</h3>
                <p>
                    Welcome! I'm Jason, a Data Scientist based in NYC.
                    I have many interests, both professional and personal.
                    Please visit my <Link to="/about" class="hyperlink">about</Link> page to learn more.
                </p>
            </section>

            <section className="card">
                <h3>Projects</h3>
                <p>
                    I develop and maintain personal Machine Learning projects some of which receive write-ups here. 
                    Please visit my <Link to="/projects" class="hyperlink">projects</Link> page to learn more.
                </p>
            </section>

            <section className="card">
                <h3>Soccer and Goalkeeping</h3>
                <p>
                    I am a former collegiate soccer goalkeeper, and an occasional goalkeeping coach. 
                    If you are interested talking soccer, competitively or for fun, 
                    please visit my <Link to="/soccer" class="hyperlink">soccer</Link> page to learn more.
                </p>
            </section>

            <section className="card">
                <h3>Connecting</h3>
                <p>
                    I am happy to <Link to="/contact" class="hyperlink">connect</Link> with analytics professionals and any with shared interests. 
                    Feel free to reach out 
                    via <a href="https://linkedin.com/in/jason-livingston-a8bb9325" target="_blank" class="hyperlink">LinkedIn</a> or <a href="mailto:jay.s.livingston@gmail.com?subject=Contact Jason" target="_blank" class="hyperlink">
                        shoot me an email!
                    </a>
                </p>
            </section>

        </div>
    );
};

export default Home;